import React ,{ useState, useEffect } from 'react'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Axios from "axios";

import exportValue from "../apiconfig";


const Registration = () => {
  if (localStorage.getItem('mojo_loggedIn')) {
		navigate('/chat-box')
    } 
  let navigate = useNavigate();

  const [stateCustomer, setStateCustomer] = useState({ user_type: 1, account_type: "Personal", full_name: "", email: "", password: "", confirmPassword: "", aggrement: true })


  const handleChange = (event) => {
    console.log(event);
    if (event.target.type == "checkbox") {
      setStateCustomer({
        ...stateCustomer,
        [event.target.name]: event.target.checked,
      });
    } else {
      setStateCustomer({
        ...stateCustomer,
        [event.target.name]: event.target.value,
      });
    }
  }

  const submitCustomer = (event) => {
    event.preventDefault();
    let flag = true;
    if (!stateCustomer.aggrement) {
      flag = false;
      console.log("not aggree");
    }
    if (stateCustomer.password != stateCustomer.confirmPassword) {
      flag = false;
      toast.configure();
      toast.error("Confirm password not match");
      console.log("not match");
    }
    if (flag) {
      axios_registration_api(stateCustomer);
      console.log("submited");
    }
  }

  const axios_registration_api = (savevalue) => {

    let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/user/registration`;
    let sendData = savevalue;
    console.log(sendData);
    Axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
      console.log(res);
      if(res.data.status == "success") {
      
        //localStorage.setItem('loggedIn', JSON.stringify(res.data.output));
        console.log("---------------------------");
         navigate('/login')
      
    }
      toast.configure();
      toast.success("Account Successfully Created.");
    }).catch((e) => {
      //setState({...state,isLoading: false});

      toast.configure();
      toast.error("Some thing went wrong");
      console.log("----error:   ", e);
    });

  };

  return (
    <div>
        <section class="vh-100 " style={{backgroundColor: "#999999"}}>
  <div class="container h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-lg-12 col-xl-11">
        <div class="card text-black" style={{borderRadius: "25px"}}>
          <div class="card-body p-md-5">
            <div class="row justify-content-center">
              <div class="col-md-10 col-lg-6 col-xl-5 order-2 order-lg-1">

                <p class="text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4">Sign up</p>

                <form class="mx-1 mx-md-4" autoComplete='off' onSubmit={(e) => (submitCustomer(e))}>

                  <div class="d-flex flex-row align-items-center mb-4">
                    <i class="fas fa-user fa-lg me-3 fa-fw"></i>
                    <div class="form-outline flex-fill mb-0">
                      <input type="text" id="form3Example1c" class="form-control" placeholder='Enter your name'  name="full_name" onChange={(e) => handleChange(e)}/>
                      {/* <label class="form-label" for="form3Example1c">Your Name</label> */}
                    </div>
                  </div>

                  <div class="d-flex flex-row align-items-center mb-4">
                    <i class="fas fa-envelope fa-lg me-3 fa-fw"></i>
                    <div class="form-outline flex-fill mb-0">
                      <input type="email" id="form3Example3c" class="form-control" placeholder='Enter your Email' name="email"  onChange={(e) => handleChange(e)}/>
                      {/* <label class="form-label" for="form3Example3c">Your Email</label> */}
                    </div>
                  </div>

                  <div class="d-flex flex-row align-items-center mb-4">
                    <i class="fas fa-lock fa-lg me-3 fa-fw"></i>
                    <div class="form-outline flex-fill mb-0">
                      <input type="password" id="form3Example4c" class="form-control" placeholder='Enter your password' name='password'  onChange={(e) => handleChange(e)}/>
                      {/* <label class="form-label" for="form3Example4c">Password</label> */}
                    </div>
                  </div>

                  <div class="d-flex flex-row align-items-center mb-4">
                    <i class="fas fa-key fa-lg me-3 fa-fw"></i>
                    <div class="form-outline flex-fill mb-0">
                      <input type="password" id="form3Example4cd" class="form-control" placeholder='Confirm your password'  name="confirmPassword" onChange={(e) => handleChange(e)}/>
                      {/* <label class="form-label" for="form3Example4cd">Repeat your password</label> */}
                    </div>
                  </div>

                  <div class="form-check d-flex justify-content-center mb-5">
                    <input class="form-check-input me-2" type="checkbox" value="" id="form2Example3c"  name="aggrement"
                              defaultChecked={stateCustomer.aggrement}
                              onChange={(e) => handleChange(e)} />
                    <label class="form-check-label" for="form2Example3">
                      I agree all statements in <a href="#!">Terms of service</a>
                    </label>
                  </div>
                 
                  {(stateCustomer.aggrement) ? 
                  <div class="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                    <button className="btn btn-primary"
                              type="submit"
                              value="Submit"
 >Register</button>
                  </div>
                  
:""}
 <div class="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                    <a href="/login"
                              
 > Click Here To Login </a>
                  </div>
                </form>

              </div>
              <div class="col-md-10 col-lg-6 col-xl-7 d-flex align-items-center order-1 order-lg-2">

                <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-registration/draw1.webp"
                  class="img-fluid" alt="Sample image"/>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
       
    </div>
  )
}

export default Registration

